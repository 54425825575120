<template>
  <v-container
    id="dashboard-view"
    style="background-color:#E5E5E5;"
    fluid
    tag="section"
  >
    <custom-loading
      :loading="loading || loading_remito"
      color="greyTxt"
      opacity=".4"
    />
    <form-modal
      :form="dataToSavePhoto"
      max-width="800px"
      :open.sync="dialogBrowsePhoto"
      @confirm="asociarPhotoConfirm"
    />
    <back class="mt-6" />

    <v-row
      v-if="!loading"
      class="mb-6"
    >
      <v-col
        md="9"
        lg="9"
        xl="10"
      >
        <v-row>
          <v-col
            v-if="remito_selected && !loading"
            class="ml-2 font-weight-medium text-h6 greyTxt--text pt-2 mt-4 mb-4"
            cols="12"
          >
            Remito
            <span class="primary--text">
              {{ remito_selected.numero_remito }}
            </span>
            de pedido
            <span
              class="sendBtn--text font-weight-bold cursor-pointer"
              @click="openDetallesPedido({raz_soc:remito_selected.raz_soc,id:orden_entrega,id_pedido})"
            >
              #{{ id_pedido }}
            </span>
            <span
              v-if="orden_entrega"
              class="font-weight-regular text-h6 primary--text d-block d-lg-inline-block"
            >
              ( <span class="d-none d-xl-inline-block">Orden de entrega</span> {{ orden_entrega }})
            </span>
            <span
              class="greyTxt--text text-subtitle-1"
            >
              {{ remito_selected.raz_soc }}
            </span>
          </v-col>
          <v-col
            cols="12"
          >
            <v-card
              elevation="2"
            >
              <!-- Tabla de Remitos -->
              <generic-table
                :loading="loading"
                :headers="headers_remito"
                :items="remito_selected.detalles"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        md="3"
        lg="3"
        xl="2"
      >
        <v-row>
          <v-col
            cols="12"
            class="mt-4"
          >
            <v-card elevation="2">
              <v-row justify="center">
                <v-chip
                  :color="remito_selected.estado | colorStatus"
                  dark
                  class="mt-4 text-center"
                >
                  <div class="mr-4 ml-4 font-weight-regular text-center">
                    {{ remito_selected.estado }}
                  </div>
                </v-chip>
              </v-row>
              <v-row
                justify="center"
                class="mt-6"
              >
                <div
                  class="text-subtitle-2 greyTxt--text font-weight-regular"
                >
                  Fecha estimada de entrega
                </div>
              </v-row>
              <v-row
                justify="center"
                class="mb-6"
              >
                <div
                  class="text-h6 greyTxt--text font-weight-negrita"
                >
                  {{ remito_selected.fecha_estimada_entrega }}
                </div>
              </v-row>
              <!-- <v-row justify="center">
                <generic-qr
                  :url="remito_selected.id_control"
                />
              </v-row> -->
              <v-row justify="center">
                <v-col cols="11">
                  <v-btn
                    class="text-none font-weight-regular mt-2"
                    color="primary"
                    small
                    dark
                    block
                    @click="downloadPDF()"
                  >
                    DESCARGAR PDF
                  </v-btn>
                </v-col>
                <v-col
                  cols="11"
                  md="9"
                >
                  <div
                    class="text-subtitle-2 greyTxt--text font-weight-negrita text-center mb-4"
                  >
                    Foto cliente
                  </div>
                  <v-sheet
                    v-if="!remito_selected || !remito_selected.archivos_cliente || remito_selected.archivos_cliente.length == 0"
                    color="white"
                    elevation="1"
                    tile
                    height="230"
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <div>
                        Sin foto asociada
                      </div>
                    </v-row>
                  </v-sheet>
                  <v-carousel
                    v-else
                    height="230"
                    hide-delimiters
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                      v-for="(archivo,i) in remito_selected.archivos_cliente"
                      :key="i"
                      :src="archivo.url"
                      :href="archivo.url"
                      target="_blank"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    />
                  </v-carousel>
                </v-col>
                <v-col
                  cols="11"
                  md="9"
                >
                  <div
                    class="text-subtitle-2 greyTxt--text font-weight-negrita text-center mb-4"
                  >
                    Foto proveedor
                  </div>
                  <v-sheet
                    v-if="!remito_selected || !remito_selected.archivos_proveedor || remito_selected.archivos_proveedor.length == 0"
                    color="white"
                    elevation="1"
                    tile
                    height="230"
                  >
                    <v-row
                      class="fill-height"
                      align="center"
                      justify="center"
                    >
                      <div>
                        Sin foto asociada
                      </div>
                    </v-row>
                  </v-sheet>
                  <v-carousel
                    v-else
                    height="230"
                    hide-delimiters
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                      v-for="(archivo,i) in remito_selected.archivos_proveedor"
                      :key="i"
                      :src="archivo.url"
                      :href="archivo.url"
                      target="_blank"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    />
                  </v-carousel>
                </v-col>
                <v-col
                  cols="11"
                  class="pt-0"
                >
                  <v-btn
                    class="text-none font-weight-regular"
                    color="primary"
                    small
                    dark
                    block
                    @click="asociarPhoto"
                  >
                    ASOCIAR FOTO
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { colorStatus } from '@/mappers/status'
  import { get, call } from 'vuex-pathify'
  import { dateHelpers } from '@/util/helpers'
  import { remitoController } from '@/pdf/remitos'
  export default {
    name: 'RemitoView',
    filters: {
      colorStatus,
    },
    data () {
      return {
        dataToSavePhoto: false,
        dialogBrowsePhoto: false,
        loading: true,
        items: [],
        dateHelpers,
      }
    },

    computed: {
      group: get('user/group'),
      id_proveed () {
        return this.$route?.query?.id_proveed
      },
      id () {
        return this.$route?.params?.id
      },
      orden_entrega () {
        return this.$route?.params?.nro_orden
      },
      id_pedido () {
        return this.$route?.params?.id_pedido
      },
      loading_remito: get('remitos/loading'),
      headers_remito: get('remitos/headers_detalle_remito'),
      remito_selected: get('remitos/remito_selected'),
    },
    async mounted () {
      await this.initialize()
      this.loading = false
    },
    methods: {
      getDataRemito: call('remitos/getRemito'),
      sendPhoto: call('remitos/sendPhoto'),
      async initialize () {
        return Promise.all([this.getDataRemito({ id_control: this.id })])
      },

      downloadPDF () {
        remitoController.dowloadRemito(this.remito_selected)
      },
      // permite asociar una foto
      asociarPhoto () {
        let form = null

        try {
          form = require('@/jsons/remitos/add-photo.json')
        } catch {
          console.log('No se encontró el json')
        }
        this.dataToSavePhoto = form
        this.dialogBrowsePhoto = true
      },
      async asociarPhotoConfirm (data) {
        this.loading = true
        const dataToSend = { id_control: this.remito_selected.id_control, ...data.data }
        await this.sendPhoto(dataToSend)
        await this.initialize()
        this.dialogBrowsePhoto = false
        this.loading = false
      },
      openDetallesPedido (details) {
        this.$router.push({ path: `/pedido/${details.id}/${details.raz_soc}/${details.id_pedido}`, query: { id_proveed: this.id_proveed } })
      },
    },
  }
</script>
